export default {
  primary: '#00919d',
  secondary: '#4c2795',
  tertiary: '#989998',
  text: '#838383',
  background: 'white',
  backgroundSecondary: '#f4e9c5',
  light: '#FFF',
  dark: '#202020'
}
