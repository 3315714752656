export default {
  multiSite: {
    '.column': {
      backgroundColor: ['', '', '', '#fffaea']
    },

    '.imageContainer': {
      img: {
        maxWidth: ['190px', '210px', '', '300px']
      }
    },

    '.logo': {
      backgroundColor: ['primary', '', 'transparent'],
      height: 'auto',
      img: {
        maxHeight: ['110px', '', '', '150px']
      }
    },

    '.locationTitle': {
      color: ['#f2f2f2', '', '', 'white']
    },
    '.enterSiteBtn': {
      variant: 'buttons.primary',
      letterSpacing: '1px',
      fontFamily: 'GreekishTitle, sans serif'
    },
    '.CTAButton': {
      variant: 'buttons.primary',
      letterSpacing: '1px',
      fontFamily: 'GreekishTitle, sans serif'
    },
    '.locationArrow': {
      color: 'primary',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      padding: '1rem 0.5rem 0rem',
      fontFamily: 'GreekishTitle, sans serif',
      fontSize: '1rem'
    }
  },

  // ? ==========================

  navigation: {
    svg: {
      fill: 'white',
      marginRight: '5px'
    },
    '.locationSwitcher button': {
      variant: 'buttons.primary',
      letterSpacing: '1px',
      flexDirection: 'row'
    },
    '.locations': {
      a: {
        variant: 'buttons.primary'
      }
    }
  },
  // ? ==========================

  footer: {
    backgroundColor: 'primary',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1635257014/sites/thegreekish/octopus-leg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',
    backgroundPosition: 'bottom right',
    color: 'white',
    paddingBottom: ['5rem', '', 'unset'],
    svg: {
      fill: 'white'
    },
    '.locationSwitcherV1 button': {
      variant: 'buttons.primary',
      letterSpacing: '1px',
      flexDirection: 'row'
    },
    '.locations': {
      a: {
        variant: 'buttons.primary'
      }
    }
  },

  ctaWidget: {
    zIndex: '999',
    color: 'light'
  },

  contactForm: {
    color: 'black'
  },

  // ? ===================
  // ? === reuseables ====
  // ? ===================

  title: {
    letterSpacing: '1px',
    color: 'primary',
    fontSize: ['2rem', '', '3rem', '4rem'],
    // borderBottom: '1px solid',
    position: 'relative',
    // left: '-2rem',
    // paddingLeft: '3rem',
    // marginBottom: '3rem',
    fontFamily: 'body',
    fontWeight: '300',
    color: '#505a5a',
    textTransform: 'uppercase'
  },

  subtitle: {
    color: 'primary',
    fontFamily: 'body'
  },

  text: {
    fontWeight: '300',
    whiteSpace: 'break-spaces',
    p: {
      fontWeight: '300'
    }
  },

  sideBySide1: {
    padding: ['1rem', '', '3rem', '4rem'],
    backgroundColor: '#00919d17',
    '.content': {
      padding: ['1rem', '', '2rem', '3rem 3rem 3rem 1rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },

    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ?  ===  Homepage ====

  homepageHero: {
    '.title': {
      color: '#ffffff',
      textAlign: 'center',
      letterSpacing: '1px'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  shoutSection: {
    padding: ['1rem', , '3rem', '4rem 4rem 6rem', '7rem 5rem 9rem'],
    '.imageContainer': {
      width: ['', '', '35%'],
      position: 'relative',
      left: ['', '', '2rem'],
      '::before': {
        height: '100%',
        width: '100%',
        content: "''",
        position: 'absolute',
        left: '-2rem',
        top: '-2rem',
        border: 'solid 2px',
        borderColor: 'secondary'
      },
      img: {
        objectFit: 'contain'
      }
    },
    '.content': {
      position: 'relative',
      top: ['', '', '4rem'],
      left: ['', '', '2rem'],
      width: '65%',
      border: '2px solid',
      borderColor: 'primary',
      margin: ['0rem 1rem', '', '0rem 3rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      padding: ['1rem', '', '2rem', '3rem'],
      '.title': {
        variant: 'customVariants.title',
        margin: '0rem 0rem 1rem',
        // fontSize: '1.75rem',
        textAlign: 'left',
        maxWidth: 'unset',
        color: 'primary'
      },

      '.text': {
        variant: 'customVariants.text',
        margin: '0rem 0rem 1rem',
        textAlign: 'left',
        maxWidth: 'unset',
        p: {
          lineHeight: '1.75'
        }
      },
      '.date': {
        margin: '0rem 0rem 2rem',
        textAlign: 'left',
        color: 'black'
      }
    }
  },

  scriptWidget: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '20vh 5vw 20vh 10vw',
    flexDirection: 'column',
    backgroundImage:
      "linear-gradient( rgba(0,0,0,0.5),rgba(0,0,0,0.5) ),url('https://res.cloudinary.com/gonation/image/upload/v1599758983/sites/thegreekish/events%20hero.jpg')",
    backgroundAttachment: ['', '', '', 'fixed'],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'left',
      alignItems: 'flex-start',
      padding: '2rem 0rem'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      textAlign: 'left'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
      // marginTop: '2rem',
    }
  },

  HomepageAboutSectionV1: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '20vh 5vw 20vh 10vw',
    flexDirection: 'column',
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      textAlign: 'left'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      marginTop: '2rem'
    }
  },

  sectionNavLinks: {
    padding: ['0rem', , '0rem', '0rem', '0rem'],
    '.boxes': {
      maxWidth: 'unset',
      padding: '0.5rem'
    },

    '.box': {
      height: ['', '', '', '400px'],
      position: 'relative',
      '::after': {
        background: 'linear-gradient(335deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0) 100%)',
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: '0rem',
        top: '0rem'
      }
    },
    '.linkName': {
      zIndex: '100',
      fontFamily: 'body',
      borderBottom: '2px solid white'
    }
  },

  homepageEventsBlock: {
    '.eventItemTitle': {
      fontFamily: 'body',
      fontWeight: '300'
    }
  },

  // ?  ===  About page ====

  aboutSideBySide1: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: ['1rem', , '2rem'],
    flexDirection: ['column', 'column', 'column', 'row'],
    img: {
      order: ['1'],
      width: ['100%', '100%', '100%']
    },
    '& > div': {
      order: ['2'],
      width: ['100%', '100%', '100%', '50%']
    }
  },

  aboutSideBySide2: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: ['1rem', , '2rem'],
    flexDirection: ['column', 'column', 'column', 'row'],
    img: {
      width: ['100%', '100%', '100%']
    },
    '& > div': {
      width: ['100%', '100%', '100%', '50%']
    }
  },

  // ? ================
  // ? === catering ===
  // ? ================

  cateringPageHero: {
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/w_2500/q_auto/f_auto/gonation.data.prod/cohwyc1kluaje8dcqyjp)',
    '.content': {
      alignSelf: 'flex-end',
      '.title': {
        display: 'none'
      },
      '::after': {
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1646848041/sites/thegreekish/KrackenPearl-Logo-smaller.png)',
        content: "''",
        height: '150px',
        width: '300px',

        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
      }
    }
  },

  foodTruckHero: {
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/w_2500/q_auto/f_auto/sites/thegreekish/IMG_7949.jpg)',
    '.content': {
      alignSelf: 'flex-end',
      '.title': {
        display: 'none'
      },
      '::after': {
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1651504062/sites/thegreekish/TheTacoish-Logo.png)',
        content: "''",
        height: '150px',
        width: '300px',

        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
      }
    }
  },

  catering1: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'transparent',
    'div.content': {
      padding: ['1rem', '', '1rem 1rem 1rem 3rem']
    }
  },
  catering2: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'transparent',
    flexDirection: ['column', 'column', 'column', 'column'],
    '.content': {
      order: '2',
      width: '100%',
      alignItems: 'center',
      textAlign: 'center',
      p: {
        textAlign: 'center'
      },
      '.title': {
        order: '1'
      },
      '.subtitle': {
        order: '2',
        border: 'none'
      },
      '.text': {
        order: '3'
      },
      a: {
        order: '4'
      }
    },

    '.imageContainer': {
      flexDirection: 'row',
      width: '100%',
      '.imageColumn': {
        flexDirection: 'row',
        '.image': {
          flex: '1',
          width: '100%',
          height: '300px'
        }
      },
      '.imageColumn1': {
        width: '33.3%'
      },
      '.imageColumn2': {
        width: '66.6%'
      }
    }
  },
  catering3: {
    variant: 'customVariants.sideBySide1'
  },
  catering4: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'transparent',
    'div.content': {
      padding: ['1rem', '', '1rem 1rem 1rem 3rem']
    }
  }
}
