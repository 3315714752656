const variantName = 'menuV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: ['3rem 0.5rem', '3rem 1rem'],
      justifyContent: 'center',
      width: '100%',

      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    backToMenuBtn: {
      variant: 'buttons.primary'
      // backgroundColor: 'primary',
      // border: 'none',
      // margin: '0.5rem',
      // cursor: 'pointer',
      // justifyContent: 'center',
      // paddingBottom: 'calc(0.5rem - 1px)',
      // paddingLeft: '1rem',
      // paddingRight: '1rem',
      // paddingTop: 'calc(0.5rem - 1px)',
      // textAlign: 'center',
      // whiteSpace: 'nowrap',
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    imageFill: {
      paddingBottom: '100%' // * reuseable to make images square
    },

    // ?================================
    // ?===========  TABS  =============
    // ?================================

    tabsRow: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'scroll',
      padding: '20px 0',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      justifyContent: ['', '', '', 'center'],
      '.sectionTab': {
        margin: '10px'
      }
    },

    // ?================================
    // ?=========  ALL IN  =============
    // ?================================
    allInContainerWrapper: {
      padding: '1rem',

      // ! GOOD LUCK UNDERSTANDING THIS..... Sorry
      // menusection title first level
      '> .allInContainer > .menuContainer': {
        paddingBottom: '0rem',
        '.menuSectionTitle': {
          color: 'primary',
          fontSize: '2.5rem'
        }
      },

      // menusection second nested level
      '> .allInContainer > .allInContainer > .menuContainer': {
        '.menuSectionTitle': {
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          color: 'tertiary',
          width: '100%',
          fontSize: ['1.75rem', '', '2.25rem'],
          '::after': {
            content: "''",
            width: '100%',
            margin: '0rem 0rem 0rem 1rem',
            height: '3px',
            paddingLeft: '20px',
            backgroundColor: 'primary',
            display: 'flex'
          }
        }
      },

      // menusection third nested level
      '> .allInContainer > .allInContainer > .allInContainer >  .menuContainer': {
        '.menuSectionTitle': {
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          color: 'primary',
          borderBottom: 'dotted 1px',
          borderColor: 'grey',
          width: '100%',
          fontSize: ['1.25rem', '', '1.5rem']
        }
      }
    },

    allInContainer: {},
    menuContainer: {},

    // ?================================
    // ?=========  CELLS  ==============
    // ?================================

    cellContainer: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },

    sectionCell: {
      cursor: 'pointer',
      width: ['100%', 'calc(50% - (0.5rem * 2))', 'calc(33.333% - (0.5rem * 2))'],
      backgroundColor: 'secondary',
      margin: ['1rem 0', '0.5rem']
    },

    menuCell: {
      flexWrap: 'wrap',
      border: 'solid 2px ',
      borderColor: 'primary',
      height: '100%',
      backgroundColor: 'background'
    },
    cellImageContainer: {
      position: 'relative',
      width: '100%',
      height: 'unset'
    },
    cellImage: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    cellImageLogo: {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    cellName: {
      margin: '1rem',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      fontFamily: 'heading',
      color: 'tertiary',
      textTransform: 'uppercase'
    },

    // ?================================
    // ?========  Menu Sections ========
    // ?================================

    menuSectionTitle: {
      fontSize: ['1.5rem', '', '2rem'],
      textAlign: 'left',
      margin: '2rem 0rem 0.5rem',
      color: 'tertiary'
    },
    menuSectionDescription: {
      fontSize: '1rem',
      marginBottom: '1.25rem'
    },
    menuItemsContainer: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 0
    },

    // ?================================
    // ?========  Menu Items ===========
    // ?================================

    menuItemContainer: {
      display: 'flex',
      overflow: 'hidden',
      marginRight: '1rem',
      width: ['100%', '', 'calc(50% - (0.5rem * 2))']
    },
    menuItemContainerImgActive: {
      variant: `${variantName}.menuItemContainer`
    },
    menuItemInnerContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch'
    },

    // ?================================
    // ?======  Menu Item Image  =======
    // ?================================

    menuItemImageContainer: {
      borderRadius: '10px 10px 0 0',
      position: 'relative',
      cursor: 'pointer',
      maxWidth: ['30%', '150px'],
      maxHeight: ['150px']
    },
    itemImageFill: {},
    menuItemImg: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      padding: '0 0.75rem 0 0',
      cursor: 'pointer'
    },
    menuItemImgDefault: {
      position: 'unset',
      objectFit: 'contain',
      display: 'none'
    },

    // ?================================
    // ?=====  Menu Item Content  ======
    // ?================================

    menuItemContentContainer: {
      height: '100%',
      flexDirection: 'column',
      flexGrow: 2,
      // flexBasis: 2,
      width: '100%',
      margin: '1rem 0rem',
      position: 'relative'
    },
    menuItemName: {
      fontSize: ['1rem', '1.1rem', '', '1.2rem'],
      margin: '0px',
      fontWeight: '600',
      textTransform: 'uppercase',
      marginBottom: '1rem',
      paddingRight: '8px'
    },
    menuItemDescription: {
      fontSize: '0.9rem',
      whiteSpace: 'break-spaces',
      width: '90%',
      opacity: '0.8'
    },

    // ?================================
    // ?======  Menu Items Price =======
    // ?================================

    menuPriceContainer: {
      flexGrow: '1',
      fontSize: ['1rem', '1.1rem'],
      justifyContent: 'flex-end'
    },

    menuItemPrice: {
      margin: '0px',
      textAlign: 'right'
    },

    variantsContainer: {},

    variantContainerFloatTopRight: {
      position: 'absolute',
      right: '0rem',
      top: '0rem'
      // float: 'right',
    },

    labelTitle: {
      display: 'flex'
    },
    variantContainer: {
      marginBottom: '8px',
      display: 'flex'
    },

    menuItemPriceLabel: {
      margin: '0px',
      marginRight: '8px'
    },
    menuItemPriceVariants: {},

    // ?================================
    // ?======= Section Dropdown =======
    // ?================================

    dropdownContainer: {
      justifyContent: 'center',
      position: 'relative',
      marginBottom: '2rem',
      display: 'none !important'
    },

    menuDropdownBtn: {
      background: 'primary',
      border: '2px solid',
      textTransform: 'uppercase',
      fontWeight: '600',
      fontSize: '1.25rem',
      display: 'inline-flex',
      alignItems: 'center',
      padding: '0.75rem 1.75rem',
      zIndex: '10'
    },

    menuSectionsDropDownContainer: {
      backgroundColor: 'background',
      boxShadow: '2px 2px 8px lightgrey',
      border: 'solid 1px',
      borderColor: 'tertiary',
      borderRadius: '10px',
      position: 'absolute',
      top: '100%',
      zIndex: '100',
      margin: '0rem 2.5% 0rem',
      display: 'flex',
      maxWidth: '600px',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      div: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1rem',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }
    },

    dropdownSection: {
      borderLeft: 'solid 3px',
      borderColor: 'tertiary',
      width: ['calc(50% - (0.5rem * 2))'],
      margin: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '1rem',
      span: {
        '&:hover': {
          textDcoration: 'underline'
        }
      }
    }
  }
}
