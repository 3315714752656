export default {
  primary: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 2rem',
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '0px',
    textDecoration: 'none',
    textAlign: 'center',
    transition: 'all ease-in-out 0.3s',
    fontSize: '1rem',
    cursor: 'pointer',
    letterSpacing: '1px',
    fontWeight: 'bold',
    fontFamily: 'body',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'primary'
    }
  },
  secondary: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '0px',
    textDecoration: 'none',
    textAlign: 'center',
    transition: 'all ease-in-out 0.3s',
    ':hover': {
      backgroundColor: 'primary',
      color: 'light'
    }
  }
}
