export default {
  heading: 'GreekishTitle',
  body: 'Open Sans, san-serif',
  monospace: 'Menlo, monospace',
  display: 'GreekishTitle',
  googleFonts: ['Roboto', 'Cinzel', 'Open Sans:100,200,300,400,500,600,700,800,900'],
  customFamilies: ['GreekishTitle', 'Avenir Next'],
  customUrls: [
    'https://www.gonation.biz/fonts/greekish/greekish.css',
    'https://www.gonation.biz/fonts/avenirnext/avenirnext.css'
  ]
}
